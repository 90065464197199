const ifRegister = (to, from, next) => {
  const stateLocal = localStorage.getItem("successRegister");
  if (stateLocal != "true") {
    next("signup");
    return;
  }
  next();
};

const routes = [
  {
    path: "/",
    component: () => import("../views/Home.vue"),
    children: [
      {
        path: "/",
        alias: "/home",
        name: "Home Section",
        component: () => import("../components/web/HomeSection.vue"),
        children: [
          {
            path: "/watch",
            component: () => import("../components/web/Watch.vue"),
            meta: {
              sitemap: {
                ignoreRoute: true,
              },
            },
          },
          {
            path: "/watch-text",
            component: () => import("../components/web/PresentSectionModal.vue"),
          },
          {
            path: "/about",
            component: () => import("../components/web/HomeOurCompanyAbout.vue"),
            meta: {
              sitemap: {
                priority: 0.7,
              },
            },
          },
        ],
      },
      {
        path: "signup",
        alias: ["/register", "registrate"],
        component: () => import("../components/web/SignupNewSection.vue"),
        children: [
          {
            path: "success",
            name: "SignupSuccess",
            component: () => import("../components/web/SignupSuccess.vue"),
            beforeEnter: ifRegister,
          },
        ],
      },
      {
        path: "/gallery",
        alias: "/galeria",
        component: () => import("../components/web/GallerySection.vue"),
        children: [
          {
            path: "/",
            component: () => import("../components/web/GallerySectionList.vue"),
            meta: {
              sitemap: {
                priority: 0.7,
              },
            },
          },
        ],
      },
      {
        path: "/webcast",
        alias: "/webcast",
        component: () => import("../components/web/WebCast.vue"),
      },
      {
        path: "/blog",
        component: () => import("../components/web/BlogSection.vue"),
        children: [
          {
            path: "/",
            component: () => import("../components/web/BlogSectionList.vue"),
            meta: {
              sitemap: {
                ignoreRoute: true,
              },
            },
          },
        ],
      },
      {
        path: "/blog/post",
        component: () => import("../components/web/BlogSectionPost.vue"),
        meta: {
          sitemap: {
            ignoreRoute: true,
          },
        },
      },
      {
        path: "/help",
        component: () => import("../components/web/HelpSection.vue"),
        meta: {
          sitemap: {
            priority: 0.7,
          },
        },
      },
      {
        path: "/contact",
        component: () => import("../components/web/ContactSection.vue"),
        meta: {
          sitemap: {
            priority: 0.9,
          },
        },
      },

      {
        path: "/jobs",
        component: () => import("../components/jobs/jobs.vue"),
        children: [
          {
            path: "/jobs/watch",
            component: () => import("../components/web/Watch.vue"),
          },
        ],
      },
      {
        path: "/jobs/:id",
        component: () => import("../components/jobs/postulationForm.vue"),
      },
    ],
  },
  {
    path: "/entrevistas",
    component: () => import("../components/interview/ViewInterview.vue"),
    props: true,
    children: [
      {
        path: "/",
        name: "interviewLogin",
        component: () => import("../components/interview/LoginInterview.vue"),
      },
      {
        path: "/forminterview",
        name: "interview",
        component: () => import("../components/interview/Interview.vue"),
      },
      {
        path: "/interviewPrint",
        name: "interviewPrint",
        component: () => import("../components/interview/InterviewPrint.vue"),
      },
    ],
  },
];

export default routes;
