import axios from "axios";
import store from "@/store";

const getAuthToken = () => localStorage.getItem(process.env.VUE_APP_TOKEN_NAME) || null;

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_SESSION_API,
  headers: {
    "Content-Type": "application/json",
    Authorization: getAuthToken(),
    app: process.env.VUE_APP_KEY_NAME,
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = getAuthToken();
    if (token) {
      config.headers["Authorization"] = `${token}`;
    }
    return config;
  },
  (error) => {
    console.error("Request error:", error);
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    const { data } = response;
    if (data.hasOwnProperty("validSession") && !data.validSession) {
      store.dispatch("user/logoutClient");
      window.location.href = "/sesionwrong";
    }
    return response;
  },
  (error) => {
    console.error("Response error:", error);
    return Promise.reject(error);
  }
);

export default axiosInstance;
