module.exports = {
  autologin: `<img src="https://new-web-site-bucket.s3.amazonaws.com/apps/icons/autologin.svg" />`,
  website: `<img src="https://new-web-site-bucket.s3.amazonaws.com/apps/icons/website.svg" />`,
  administration: `<img src="https://new-web-site-bucket.s3.amazonaws.com/apps/icons/administration.svg" />`,
  reservations: `<img src="https://new-web-site-bucket.s3.amazonaws.com/apps/icons/reservations.svg" />`,
  statistics: `<img src="https://new-web-site-bucket.s3.amazonaws.com/apps/icons/statistics.svg" />`,
  pays: `<img src="https://new-web-site-bucket.s3.amazonaws.com/apps/icons/pays.svg" />`,
  ipsecure: `<img src="https://new-web-site-bucket.s3.amazonaws.com/apps/icons/ipsecure.svg" />`,
  home: `<svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 7.10595C0 6.23333 0.402396 5.40946 1.09063 4.87299L6.62702 0.557446C6.89156 0.351244 7.26292 0.353115 7.52536 0.561971L12.9425 4.87302C13.6175 5.41016 14.0108 6.22572 14.0108 7.08833V14.5797C14.0108 15.141 13.5557 15.596 12.9944 15.596H9.54619C8.98489 15.596 8.52986 15.141 8.52986 14.5797V11.1314C8.52986 10.6904 8.17235 10.3329 7.73132 10.3329H6.27943C5.83841 10.3329 5.48089 10.6904 5.48089 11.1314V14.5797C5.48089 15.141 5.02587 15.596 4.46457 15.596H1.01632C0.455024 15.596 0 15.141 0 14.5797V7.10595Z" fill="#343434"/>
    </svg>`,
  src: (key) => `https://myspace1a.s3.amazonaws.com/apps/icons/${key}.svg`,
};
